import React from 'react'
import Footer from './Footer'
import SubFooter from './SubFooter'

const MainFooter = () => {
  return (
    <div>
      <div>
        <Footer/>
        </div>
         <SubFooter/>
    </div>
  )
}

export default MainFooter