import React from 'react'
import FireExtenguishers from './Components/FireExtenguishers'
import FireExtinguishersFirstComp from './Components/FireExtinguishersFirstComp'
import Index from './Components/FireHydrants/FireHydrants'

const Product = () => {
  return (
    <div className=''>
        <div>
            <FireExtenguishers/>
        </div>
        <div>
           <FireExtinguishersFirstComp/>
        </div>
 
    </div>
  )
}

export default Product