import React from 'react';
import ContFirstComp from './ContFirstComp';
import ContSecComp from './ContSecComp';
import ContThirdComp from './ContThirdComp';
const ContMainPage = () => {
  return (
    <div> 
  <div> 
     <ContFirstComp/>
  </div>
  <div> 
     <ContSecComp/>
  </div>
  <div> 
     <ContThirdComp/>
  </div>

    </div>
  )
}

export default ContMainPage