import React from 'react'

const SubFooter = () => {
  return (
    <div className='SubFooter'>
        <div className='text-center pt-5' >
      <p >© 2024 BTSafe</p>
      </div>
    </div>
  )
}

export default SubFooter